html {
  scroll-padding-top: 100px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.quill.red1d {
  border: solid 1px red;
}

.pt25 {
  padding-top: 25px;
}
.mt25 {
  margin-top: 25px !important;
}
.flaggy {
  position: absolute;
  right: 15px;
  top: 30px;
}
.ql-snow a {
  color: blue !important;
}
.ql-editor {
  height: 100px;
}
.msg .quill {
  height: 250px;
}
.tablistNone .MuiTabs-root {
  display: none;
}
.tablistNone {
  padding: 0px !important;
}
.tablistNone .MuiAccordionDetails-root {
  width: 550px;
  /*padding: 0px !important;*/
}
.tablistNone .MuiPaper-elevation1 {
  box-shadow: unset;
}
.makeStyles-headerLogo-26 {
  max-height: 50px !important;
}
.colorPicker .MuiInputBase-formControl.MuiInput-formControl {
  color: rgba(0, 0, 0, 0.54) !important;
}
.spicchio {
  width: 0;
  height: 0;
  border-right: 25px solid purple;
  border-top: 25px solid transparent;
  border-left: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  position: absolute;
  top: 23px;
  left: 61px;
}

.responsiveTable {
  display: grid;
  grid-template-columns: repeat(9, 1fr); /* One column for each TableCell */
  gap: 10px;
  width: 100%;
}

.responsiveTableCell {
  display: flex;
  align-items: center;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.responsiveTableHeader {
  display: flex;
  align-items: center;
  padding: 8px;
  text-align: left;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
}

@media (max-width: 600px) {
  .responsiveTable {
      grid-template-columns: 1fr; /* Each cell takes the full width on small screens */
  }
  .responsiveTableCell, .responsiveTableHeader {
      display: block; /* Stack elements vertically in each cell */
  }
}
